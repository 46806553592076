import { Box } from "@mui/material";
import Mixed from "../ui/kosze/kosz-zmieszane.png";
import Paper from "../ui/kosze/kosz-papier.png";
import Plastics from "../ui/kosze/kosz-metale.png";
import Glass from "../ui/kosze/kosz-szkło.png";
import Bio from "../ui/kosze/kosz-bio.png";
import Meds from "../ui/kosze/kosz-apteka.png";
import ElectronicWaste from "../ui/kosze/kosz-elektroodpady.png";
import { useState } from "react";

type Props = {
  handleClick: Function;
};

const Bins = ({ handleClick }: Props) => {
  const [isPressed, setIsPressed] = useState(false);
  const [currentClickedBin, setCurrentClickedBin] = useState(-1);
  const bins = [
    { image: ElectronicWaste, binType: "elektroodpady" },
    { image: Meds, binType: "apteka" },
    { image: Mixed, binType: "zmieszane" },
    { image: Paper, binType: "papier" },
    { image: Plastics, binType: "tworzywa sztuczne" },
    { image: Glass, binType: "szklo" },
    { image: Bio, binType: "bio" },
  ];

  const handleAnimation = (binNumber: number) => {
    setCurrentClickedBin(binNumber);
    setIsPressed(true);
    setTimeout(() => {
      setIsPressed(false);
    }, 100);
  };

  return (
    <Box
      position={"absolute"}
      bottom={"0px"}
      display={"flex"}
      width={"100%"}
      justifyContent={"space-between"}
      pl={"110px"}
      pr={"90px"}
    >
      {bins.map((bin: any, index: number) => {
        return (
          <Box
            key={index}
            component={"img"}
            src={bin.image}
            width={"auto"}
            height={"275px"}
            sx={{
              cursor: "pointer",
              transition: "transform .05s ease-in-out",
              transform: `${
                isPressed
                  ? currentClickedBin === index
                    ? "scale(95%)"
                    : "scale(100%)"
                  : "scale(100%)"
              }`,
              transformOrigin: "bottom",
            }}
            onClick={() => handleClick(bin.binType)}
            onMouseDown={() => handleAnimation(index)}
          />
        );
      })}
    </Box>
  );
};

export default Bins;
