import { Box, Button } from "@mui/material";
import shelfWithString from "../ui/polka-ze-sznurkiem.png";
import polaroid from "../ui/polaroid-z-klamerka.png";
import startImage from "../ui/rozpocznij.png";

type Props = {
  itemImage: string;
  handleStartButton: Function;
  start: boolean;
};

const Item = ({ itemImage, handleStartButton, start }: Props) => {
  return (
    <Box position={"relative"} ml={"177px"} mr={"166px"}>
      <Box
        component={"img"}
        src={shelfWithString}
        width={"100%"}
        height={"100%"}
      />
      <Box
        position={"absolute"}
        component={"img"}
        src={polaroid}
        width={"auto"}
        height={"295px"}
        mx={"auto"}
        sx={{
          left: "0",
          right: "0",
          top: "50px",
        }}
      />
      <Box
        position={"absolute"}
        component={"img"}
        src={itemImage}
        width={"auto"}
        height={"172px"}
        mx={"auto"}
        sx={{
          left: "0",
          right: "0",
          top: "138px",
        }}
      />
      <Button
        disableRipple
        sx={{
          width: "445px",
          borderRadius: "40%",
          position: "absolute",
          left: "1040px",
          right: "0",
          top: "138px",
        }}
      >
        <Box
          display={start ? "none" : "block"}
          component={"img"}
          src={startImage}
          width={"auto"}
          height={"130px"}
          onClick={() => handleStartButton()}
        />
      </Button>
    </Box>
  );
};

export default Item;
