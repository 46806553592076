import { Box, Grid } from "@mui/material";
import background from "./ui/background.png";
import Header from "./components/Header";
import Question from "./components/Question";
import Item from "./components/Item";
import Bins from "./components/Bins";
import questions from "./data/questions.json";
import { useState, useEffect } from "react";
import ReactPlayer from "react-player";
import rightAnimation from "./ui/right.mp4";
import wrongAnimation from "./ui/wrong.mp4";
import winAnimation from "./ui/win.mp4";
import educationalMixed from "./ui/educational/edukacyjne-zmieszane.mp4";
import educationalPlastics from "./ui/educational/edukacyjne-tworzywa sztuczne.mp4";
import educationalPaper from "./ui/educational/edukacyjne-papier.mp4";
import educationalGlass from "./ui/educational/edukacyjne-szklo.mp4";
import educationalBio from "./ui/educational/edukacyjne-biologiczne.mp4";

function App() {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [question, setQuestion] = useState(questions.garbage[currentQuestion]);
  const [lastQuestions, setLastQuestions] = useState<any>([]);
  const [countWins, setCountWins] = useState(0);
  const [start, setStart] = useState(false);
  const [time, setTime] = useState(5);
  const [startAnimation, setStartAnimation] = useState(false);
  const [startRightAnimation, setStartRightAnimation] = useState(false);
  const [startWrongAnimation, setStartWrongAnimation] = useState(false);
  const [startWinAnimation, setStartWinAnimation] = useState(false);
  const timeForAnswer = 5;
  const questionsToWin = 3;
  const gameVideos = [
    { url: rightAnimation, state: startRightAnimation },
    { url: wrongAnimation, state: startWrongAnimation },
    { url: winAnimation, state: startWinAnimation },
  ];
  const educationalVideos = [
    educationalMixed,
    educationalPlastics,
    educationalPaper,
    educationalGlass,
    educationalBio,
  ];
  const idleTime = 60;
  const [startEducationalVideos, setStartEducationalVideos] = useState(false);
  const [currentEducationalVideo, setCurrentEducationalVideo] = useState(0);

  let idleTimer: any = null;
  const countdown = () => {
    clearTimeout(idleTimer);
    idleTimer = setTimeout(() => {
      setStartEducationalVideos(true);
    }, idleTime * 1000);
  };
  useEffect(() => {
    countdown();
  }, []);

  const resetIdleTimer = () => {
    if (startEducationalVideos) resetGame();
    setStartEducationalVideos(false);
    clearTimeout(idleTimer);
    countdown();
  };
  useEffect(() => {
    window.addEventListener("click", resetIdleTimer);
  }, []);

  const videoEndedHandler = () => {
    currentEducationalVideo + 1 < educationalVideos.length &&
      setCurrentEducationalVideo(currentEducationalVideo + 1);
    currentEducationalVideo + 1 === educationalVideos.length &&
      setCurrentEducationalVideo(0);
  };

  const nextItem = () => {
    setLastQuestions([...lastQuestions, question]);
    setCurrentQuestion(currentQuestion + 1);
    setTime(timeForAnswer);
  };

  useEffect(() => {
    setQuestion(questions.garbage[currentQuestion]);
    setTime(timeForAnswer);
  }, [currentQuestion]);

  useEffect(() => {
    if (lastQuestions.length >= questions.garbage.length) {
      setCurrentQuestion(0);
      setQuestion(questions.garbage[currentQuestion]);
      setLastQuestions([]);
    }
  }, [lastQuestions]);

  const handleBinClick = (bin: string) => {
    if (startAnimation) return;
    if (!start) return;
    if (question.correct_bin === bin) {
      setCountWins((prevWins) => prevWins + 1);
      if (countWins === questionsToWin - 1) {
        setStartWinAnimation(true);
        setStartAnimation(true);
        return;
      }
      setStartAnimation(true);
      setStartRightAnimation(true);
    } else {
      setStartAnimation(true);
      setStartWrongAnimation(true);
    }
    setTimeout(() => {
      nextItem();
    }, 100);
  };

  const resetGame = () => {
    setCurrentQuestion(0);
    setQuestion(questions.garbage[0]);
    setLastQuestions([]);
    setTime(timeForAnswer);
    setStart(false);
    setCountWins(0);
  };

  if (countWins === questionsToWin) {
    resetGame();
  }

  const handleStartButton = () => {
    setStart(true);
  };

  useEffect(() => {
    if (startAnimation) return;
    let timer: any = null;
    if (start) {
      timer = setInterval(() => {
        setTime((prevTime) => prevTime - 1);
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [start, question]);

  useEffect(() => {
    if (startAnimation) return;
    if (time === 0) {
      nextItem();
      setTime(timeForAnswer);
    }
  }, [time]);

  const animationHandler = () => {
    setStartAnimation(false);
    setStartRightAnimation(false);
    setStartWrongAnimation(false);
    setStartWinAnimation(false);
  };

  return (
    <Grid
      container
      justifyContent={"center"}
      position={"relative"}
      sx={{
        width: "100vw",
        height: "100vh",
        background: `url(${background})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Box
        display={startEducationalVideos ? "block" : "none"}
        position={"absolute"}
        zIndex={"1"}
        sx={{
          width: "100vw",
          height: "100vh",
          background: `url(${background})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
      <Box position={"absolute"} zIndex={"2"}>
        {startEducationalVideos && (
          <ReactPlayer
            url={educationalVideos[currentEducationalVideo]}
            playing={true}
            muted={true}
            width={"1920px"}
            height={"1080px"}
            onStart={resetGame}
            onEnded={videoEndedHandler}
          />
        )}
        {gameVideos.map(
          (video, index) =>
            video.state && (
              <ReactPlayer
                key={index}
                url={video.url}
                playing={true}
                muted={true}
                width={"1920px"}
                height={"1080px"}
                onStart={() => {
                  setStart(false);
                }}
                onEnded={() => {
                  video.url !== winAnimation && animationHandler();
                  setStart(video.url === winAnimation ? false : true);
                }}
                onClick={() => {
                  video.url === winAnimation && animationHandler();
                }}
              />
            )
        )}
      </Box>
      <Grid
        container
        item
        flexDirection={"column"}
        position={"relative"}
        sx={{
          "& *": {
            userSelect: "none",
          },
        }}
      >
        <Header
          countWins={countWins}
          time={time}
          timeForAnswer={timeForAnswer}
          questionsToWin={questionsToWin}
          restartFunction={resetGame}
        />
        <Question />
        <Item
          itemImage={question && question.item}
          handleStartButton={handleStartButton}
          start={start}
        />
        <Bins handleClick={handleBinClick} />
      </Grid>
    </Grid>
  );
}

export default App;
