import { createTheme } from "@mui/material";
import DMSans from "../fonts/DMSans-Regular.ttf";

const theme = createTheme({
  typography: {
    fontFamily: "DM Sans",
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'DM Sans';
          src: url(${DMSans}) format('truetype');
        }
      `,
    },
  },
});

export default theme;
