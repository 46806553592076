import { Box, Typography } from "@mui/material";

const Question = () => {
  return (
    <Box
      sx={{
        transform: "translateY(-5px)",
      }}
    >
      <Typography
        fontSize={"72px"}
        textTransform={"uppercase"}
        color={"#fffffc"}
        textAlign={"center"}
        lineHeight={"85px"}
        letterSpacing={"0.2px"}
        sx={{
          textShadow: "1px 2px 8px #222222",
        }}
      >
        <Typography
          component={"span"}
          fontSize={"72px"}
          fontWeight={"700"}
          lineHeight={"85px"}
          letterSpacing={"3px"}
        >
          w którym koszu
        </Typography>
        <br />
        powinien wylądować
        <br />
        ten odpad?
      </Typography>
    </Box>
  );
};

export default Question;
