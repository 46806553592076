import { Box, Typography } from "@mui/material";
import progressBar from "../ui/belka-postep.png";
import progressLeaf from "../ui/postep_listek.png";
import progressLeafEmpty from "../ui/postep_listek_pusty.png";
import timeImage from "../ui/czas.png";
import RefreshIcon from "@mui/icons-material/Refresh";

type Props = {
  countWins: number;
  time: number;
  timeForAnswer: number;
  questionsToWin: number;
  restartFunction: Function;
};

const Header = ({
  countWins,
  time,
  timeForAnswer,
  questionsToWin,
  restartFunction,
}: Props) => {
  const leafs = [];
  for (let i = 0; i < questionsToWin; i++) {
    leafs.push(
      <Box
        key={i}
        component={"img"}
        src={countWins > i ? progressLeaf : progressLeafEmpty}
        width={"auto"}
        height={"94px"}
        sx={{
          userSelect: "none",
        }}
      />
    );
  }
  return (
    <>
      <Box
        position={"absolute"}
        display={"flex"}
        width={"50px"}
        height={"50px"}
        top={"10px"}
        left={"10px"}
        sx={{
          "& svg": {
            width: "100%",
            height: "100%",
            fill: "#ffffff30",
          },
        }}
        onClick={() => restartFunction()}
      >
        <RefreshIcon />
      </Box>
      <Box
        position={"relative"}
        height={"140px"}
        display={"flex"}
        justifyContent={"space-between"}
        mt={"48px"}
        ml={"175px"}
        mr={"155px"}
      >
        <Box position={"relative"}>
          <Box
            component={"img"}
            src={progressBar}
            width={"auto"}
            height={"135px"}
            sx={{
              userSelect: "none",
            }}
          />
          <Box
            position={"absolute"}
            width={"100%"}
            height={"100%"}
            display={"flex"}
            justifyContent={"space-around"}
            alignItems={"center"}
            p={"35px"}
            sx={{
              top: "0",
            }}
          >
            {leafs}
          </Box>
        </Box>
        <Box position={"relative"}>
          <Box
            component={"img"}
            src={timeImage}
            width={"auto"}
            height={"170px"}
            sx={{
              transform: "translateY(-5px)",
            }}
          />
          <Typography
            position={"absolute"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            width={"80%"}
            height={"170px"}
            m={"auto"}
            textAlign={"center"}
            fontSize={"104px"}
            fontWeight={"700"}
            color={"#622E00"}
            sx={{
              userSelect: "none",
              top: "4px",
              right: "-4px",
            }}
          >
            {time > 0 ? time : timeForAnswer}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default Header;
